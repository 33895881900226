import React from "react";
import { Avatar, HStack, Image, Link, Stack, Text } from "@chakra-ui/react";

const Vancouver = () => {
  return (
    <Stack
      bg="#ebe8f1cc"
      padding="32px"
      fontFamily="Garamond"
      fontSize="xl"
      marginTop="8px"
      alignItems="flex-start"
      spacing={3}
      backgroundImage="/vancouver.jpg"
      backgroundSize="cover"
      backgroundPosition="center center"
      backgroundBlendMode="lighten"
    >
      <Stack
        backgroundColor="rgba(255,255,255,0.5)"
        padding="16px"
        alignItems="center"
        spacing={4}
      >
        <Stack direction={{ base: "column", lg: "row" }} alignItems="center">
          <Stack>
            <Text fontWeight="bold" fontSize="3xl">
              Metro Vancouver
            </Text>
            <Text>
              Hands down this is the city with the most beautiful landscape and
              weather across Canada. It is a big city, with a lot of nature like
              mountains, oceans and forests in abundance. Like any other big
              city, living in Vancouver can be a little more expensive than
              living in a smaller city across the country. There are numerous
              resources available to support your transition into the society
              especially if you are arriving as a Permanent Resident.
            </Text>
            <Text>Here are some resources to check out:</Text>
          </Stack>
          <Image
            src="/vancouver2.jpg"
            alt="Vancouver picture"
            w="450px"
            flex="1"
            display={{ base: "none", lg: "inherit" }}
          />
        </Stack>
        <HStack justifyContent="center" w="100%">
          <Avatar
            as="a"
            name="INS"
            size="2xl"
            src="/impactns.jpeg"
            href="https://impactnorthshore.ca/"
            target="_blank"
          />
          <Avatar
            as="a"
            name="ISSBC"
            size="2xl"
            src="/issbc.png"
            href="https://issbc.org/"
            target="_blank"
          />
          <Avatar
            as="a"
            name="ISSBC"
            size="2xl"
            src="/newbc.png"
            href="https://newtobc.ca/"
            target="_blank"
          />
        </HStack>
        <Text alignSelf="flex-start">
          Looking to do something fun? Visit{" "}
          <Link color="teal" href="https://604now.com/" isExternal>
            604now
          </Link>
          !
        </Text>

        <Image
          src="/vancouver2.jpg"
          alt="Vancouver picture"
          w="100%"
          flex="1"
          display={{ base: "inherit", lg: "none" }}
        />
      </Stack>
    </Stack>
  );
};

export default Vancouver;
