import React, { useEffect, useRef } from "react";
import MainHeader from "./MainHeader";
import "./Landing.css";
import { Button, Flex, Image, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Conversation from "./conversation";

const Landing = () => {
  const backgrounds = [
    ["tess.png", "tess2.png"],
    ["nan.png", "nan2.png"],
    ["nad.png", "nad2.png"],
  ];
  const backgroundInd = Math.floor(Math.random() * 3);
  const navigate = useNavigate();
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <Flex flexDirection="column" w="100%" height="100vh" alignItems="center">
      <MainHeader variant="landing" />
      <Flex
        fontFamily="Garamond"
        flex="1"
        boxSizing="border-box"
        w="100%"
        alignItems="center"
        padding="32px"
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={{ base: "space-between", md: "space-around" }}
        backgroundImage={{
          base: backgrounds[backgroundInd][1],
          md: backgrounds[backgroundInd][0],
        }}
        backgroundSize="cover"
        backgroundPosition="center center"
      >
        <Flex position="relative" height="520px" justifyContent="center">
          <Image src="/Nandina.png" alt="conversation" h="100%" />
          <Conversation />
        </Flex>
        <Stack alignItems="center" paddingY={{ base: "32px", md: "0" }} margi>
          <Button
            bgRepeat="no-repeat"
            bgSize="100%"
            h="200px"
            w="200px"
            borderRadius="50%"
            size="lg"
            fontSize="25px"
            fontFamily="Vollkorn"
            bgColor="#D4C7D5"
            _hover={{ bg: "#bfb3c0" }}
            onClick={() => navigate("info")}
          >
            {/* <ReactTyped
              strings={[
                "Start",
                "Comenzar",
                "Commencer",
                "Começar",
                "يبدأ",
                "Bẹrẹ",
                "Malite",
                "开始",
                "시작",
                "始める",
                "Simulan",
                "початок",
                "शुरू",
              ]}
              typeSpeed={100}
              loop
              backSpeed={20}
              showCursor={false}
            /> */}
            Learn more
          </Button>
        </Stack>
      </Flex>
      {/* <Flex flexDirection="column" w="100%">
        <Box position="relative">
          <Divider variant="brand" />
          <AbsoluteCenter bg="white" px="4">
            <Icon as={GoDotFill} color="#8d77ab" />
          </AbsoluteCenter>
        </Box>
        <ReviewGroup />
      </Flex> */}
    </Flex>
  );
};

export default Landing;
